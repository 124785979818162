import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Button, Grid, Typography, Modal, Box } from '@mui/material';

// Define the company options for the dropdown
const companyOptions = [
  { value: 'Anderson Underground', label: 'Anderson Underground' },
  { value: 'Selcon', label: 'Selcon' },
  { value: 'Sellenriek Construction', label: 'Sellenriek Construction' },
  { value: 'Sellenriek Energy', label: 'Sellenriek Energy' },
  { value: 'UtiliSource', label: 'UtiliSource' },
];

const SponsorshipForm = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    sponsorshipName: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    employeeStatus: '',
    sponsorshipDescription: '',
    dollarAmount: '',
    payableTo: '',
    file: null,
  });

  const [thankYouModalOpen, setThankYouModalOpen] = useState(false);
  
  useEffect(() => {
    console.log('Modal state changed:', thankYouModalOpen);
  }, [thankYouModalOpen]);

  // Handle input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle file input change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      file: file,
    }));
  };

  // Function to upload the file to Azure Blob Storage
  const uploadFileToAzureBlob = async (file) => {
    console.log('Step 1: Preparing to upload file to Azure:', file);
    const uploadResponse = await fetch('https://sponsorshipbe.azurewebsites.net/api/uploadFile', {
      method: 'POST',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    });

    console.log('Step 2: File upload response status:', uploadResponse.status);

    if (!uploadResponse.ok) {
      const errorText = await uploadResponse.text();
      console.error('Step 3: File upload failed. Response text:', errorText);
      throw new Error(`Failed to upload file: ${uploadResponse.statusText}`);
    }

    const result = await uploadResponse.json();
    console.log('Step 4: File upload result:', result);
    return result;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    console.log('Opening modal regardless of submission');
    setThankYouModalOpen(true);

    // Rest of the form submission logic
    console.log('Step 5: Starting form submission');
    const formSubmission = { ...formData };
    delete formSubmission.file;

    try {
        console.log('Step 9: Submitting form with data:', formSubmission);
        const response = await fetch('https://sponsorshipbe.azurewebsites.net/api/submitSponsorship', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formSubmission),
        }).catch(error => {
            console.error('Network error:', error);
            return null;
        });

        // If we got no response, we can still show success since the modal is already open
        if (!response) {
            console.log('No response from server, but continuing with form reset');
            setFormData({
                companyName: '',
                street: '',
                city: '',
                state: '',
                zip: '',
                sponsorshipName: '',
                contactName: '',
                contactEmail: '',
                contactPhone: '',
                employeeStatus: '',
                sponsorshipDescription: '',
                dollarAmount: '',
                payableTo: '',
                file: null,
            });
            return;
        }

        console.log('Step 10: Response status:', response.status);
        const responseText = await response.text();
        console.log('Step 11: Response body:', responseText);

        if (response.ok) {
            console.log('Step 12: Form submission successful');
            setFormData({
                companyName: '',
                street: '',
                city: '',
                state: '',
                zip: '',
                sponsorshipName: '',
                contactName: '',
                contactEmail: '',
                contactPhone: '',
                employeeStatus: '',
                sponsorshipDescription: '',
                dollarAmount: '',
                payableTo: '',
                file: null,
            });
        }
    } catch (error) {
        console.error('Error submitting form:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h4" gutterBottom>
        Sponsorship Form
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Sponsorship Name"
            name="sponsorshipName"
            value={formData.sponsorshipName}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Sponsorship Description"
            name="sponsorshipDescription"
            value={formData.sponsorshipDescription}
            onChange={handleChange}
            fullWidth
            multiline
            rows={3}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            select
            label="Which Company would you like to sponsor you?"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            fullWidth
            required
          >
            {companyOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Dollar Amount"
            name="dollarAmount"
            value={formData.dollarAmount}
            onChange={handleChange}
            fullWidth
            type="text"
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Contact Name"
            name="contactName"
            value={formData.contactName}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Contact Email"
            name="contactEmail"
            value={formData.contactEmail}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Contact Phone"
            name="contactPhone"
            value={formData.contactPhone}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            label="Are you an Employee?"
            name="employeeStatus"
            value={formData.employeeStatus}
            onChange={handleChange}
            fullWidth
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Payment Mailing Information</Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Make Checks Payable To"
            name="payableTo"
            value={formData.payableTo}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Street"
            name="street"
            value={formData.street}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="City"
            name="city"
            value={formData.city}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="State"
            name="state"
            value={formData.state}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Zip"
            name="zip"
            value={formData.zip}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Upload Supporting Document</Typography>
          <input
            type="file"
            name="file"
            onChange={handleFileChange}
            accept=".pdf,.doc,.docx,.jpg,.png"
          />
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit Sponsorship
          </Button>
        </Grid>
      </Grid>

      {/* Thank You Modal */}
      <Modal 
        open={thankYouModalOpen} 
        onClose={() => setThankYouModalOpen(false)}
        aria-labelledby="thank-you-modal"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          minWidth: 300,
          maxWidth: '90%',
          m: 2,
          position: 'relative',
          outline: 'none',
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Thank you for your submission!
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Your sponsorship request has been received.
          </Typography>
          <Button 
            onClick={() => setThankYouModalOpen(false)} 
            variant="contained" 
            color="primary"
            fullWidth
          >
            Close
          </Button>
        </Box>
      </Modal>
    </form>
  );
};

export default SponsorshipForm;